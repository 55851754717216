

















import { defineComponent } from '@vue/composition-api'
import { FaqSection } from '@/inc/types'

import FaqGrid from './Grid.vue'

export default defineComponent({
  name: 'FaqSection',
  props: {
    content: {
      type: Object as () => FaqSection,
      Default: {} as FaqSection,
    },
  },
  components: {
    FaqGrid,
  },
})
