












































































































































































import { defineComponent, ref, PropType } from '@vue/composition-api'
import { gsap } from 'gsap'
import { FaqItem } from '@/inc/types'
import { push, GtmLayer } from '@/inc/utils'

export default defineComponent({
  props: {
    content: {
      type: Object as PropType<FaqItem>,
      required: true,
    },
    active: {
      type: String,
      required: false,
      default: '',
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    grid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, ctx) {
    const isOpen = ref(props.open)
    const vBar = ref<null | HTMLElement>(null)
    const inner = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value

      if (isOpen.value === true) {
        // Push event to datalayer
        const layer: GtmLayer = {
          event: 'clic_faq',
          clickTitle: props.content.title,
        }
        push(layer)

        // Update URL with slug as hash
        if (props.content.slug) {
          const currentUrl = new URL(window.location.href)
          const newUrl = `${currentUrl.origin}${currentUrl.pathname}#${props.content.slug}`

          window.history.replaceState({}, '', newUrl)
        }
      }
    }

    const close = () => (isOpen.value = false)

    const innerAnims = {
      enter(el, done) {
        el.style.height = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()
        ctx.root.$ee.$emit('faq-item:open')

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      inner,
      innerAnims,
      isOpen,
      toggle,
      close,
      vBar,
    }
  },
})
