















































































































































































































import Slidy, { TransitionInfos } from 'epic-slidy'
import { gsap } from 'gsap'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { FaqGrid } from '@/inc/types'
import { push, GtmLayer } from '@/inc/utils'

import FaqItem from './Item.vue'

export default defineComponent({
  name: 'FaqGrid',
  props: {
    content: {
      type: Object as () => FaqGrid,
      required: true,
    },
  },

  components: {
    FaqItem,
  },

  setup(props, ctx) {
    let slider: Slidy
    let currentIndex = 0
    const list = ref<null | HTMLElement>(null)
    const slidesItem = ref<null | HTMLElement>(null)
    const faqItem = ref<InstanceType<typeof FaqItem>[]>([])
    const navItem = ref<null | HTMLElement[]>(null)
    const trigger = ref<null | HTMLElement>(null)
    const isMobile = ref(false)

    const onResize = (w: number) => (isMobile.value = w < 960)

    onMounted(() => {
      // Set active item based on hash
      const { $route } = ctx.root

      if ($route.hash) {
        const hash = $route.hash.replace('#', '')

        props.content.categories.forEach((category, index) => {
          if (category.slug === hash) {
            // Active item is a category
            currentIndex = index
          } else {
            // Active item could be a question
            const activeQuestion = category.questions.find(
              question => question.slug === hash
            )

            if (activeQuestion && faqItem.value) {
              currentIndex = index

              // Toggle active question item
              const activeFaqItem = faqItem.value.find(
                item =>
                  item.$el && (item.$el as HTMLElement).dataset.slug === hash
              )

              if (activeFaqItem) {
                activeFaqItem.toggle()
              }
            }
          }
        })
      }

      isMobile.value = window.innerWidth <= 960

      const transition = (
        currentSlide: HTMLElement,
        newSlide: HTMLElement,
        { currentIndex }: TransitionInfos
      ) => {
        const tl = gsap.timeline({
          onComplete: () => {
            currentSlide.style.zIndex = '1'
            newSlide.style.zIndex = '3'
          },
        })
        // Const current = {
        //   /* eslint-disable */
        //   pictures: picture?.value![currentIndex].$refs.picture,
        //   title: splitTitle.elements[currentIndex],
        //   text: splitText.elements[currentIndex],
        //   link: link?.value![currentIndex].$el,
        //   /* eslint-enable */
        // }

        // const next = {
        //   /* eslint-disable */
        //   pictures: picture?.value![newIndex].$refs.picture,
        //   title: splitTitle.elements[newIndex],
        //   text: splitText.elements[newIndex],
        //   link: link?.value![newIndex].$el,
        //   /* eslint-enable */
        // }

        // Close current slide items
        if (faqItem.value) {
          faqItem.value.forEach(item => {
            const { category } = (item.$el as HTMLElement).dataset

            if (category && parseInt(category, 10) === currentIndex) {
              item.close()
            }
          })
        }

        return (
          tl
            .add('transition')
            .set(newSlide, {
              zIndex: 3,
            })
            .to(
              currentSlide,
              {
                duration: 0.2,
                opacity: 0,
                onComplete: () => {
                  currentSlide.style.display = 'none'
                },
              },
              'transition'
            )
            // News Slide Management
            .to(
              newSlide,
              {
                display: 'block',
                duration: 0.2,
                opacity: 1,
              },
              'transition+=0.2'
            )
            .then()
        )
      }
      slider = new Slidy(list.value as HTMLElement, {
        pagination: false,
        click: false,
        // eslint-disable-next-line no-template-curly-in-string
        nav: false,
        transition,
        index: currentIndex,
      })
      slider.init()

      // Set active category
      if (navItem.value) {
        navItem.value[currentIndex].classList.add('is-active')
      }

      ctx.root.$ee.$on('faq-item:open', updateHeight)
    })

    const setSlide = (i: number, category: string, slug?: string) => {
      if (i === currentIndex) {
        return
      }

      if (category !== 'none') {
        const layer: GtmLayer = {
          event: 'clic_faq',
          clickTitle: category,
        }
        push(layer)
      }

      if (navItem.value) {
        navItem.value.forEach(el => {
          el.classList.remove('is-active')
        })
        navItem.value[i].classList.add('is-active')
      }

      // Update URL with category slug as hash
      if (slug) {
        const currentUrl = new URL(window.location.href)
        const newUrl = `${currentUrl.origin}${currentUrl.pathname}#${slug}`

        window.history.replaceState({}, '', newUrl)
      }

      slider.slideTo(i, 'controls')
      currentIndex = i
      updateHeight()
    }

    const updateHeight = () => {
      const tl = gsap.timeline()

      tl.to(list.value, {
        duration: 0.3,
        height: '100%',
        ease: 'power4.inOut',
      })
    }

    const toggle = () => {
      if (window.innerWidth > 768) {
        return
      }
      const isOpen = trigger!.value?.classList.contains('is-open')

      // eslint-disable-next-line no-unused-expressions
      isOpen ? close() : open()
    }

    const open = () => {
      trigger?.value?.classList.add('is-open')
      const tl = gsap.timeline()
      tl.to(trigger.value, 0.5, {
        height: 'auto',
        ease: 'Power4.easeOut',
      })
    }

    const close = () => {
      console.log('close')
      trigger?.value?.classList.remove('is-open')
      const tl = gsap.timeline()
      tl.to(trigger.value, 0.5, {
        height: '45px',
        ease: 'Power4.easeInOut',
      })
    }

    return {
      list,
      slidesItem,
      faqItem,
      navItem,
      setSlide,
      trigger,
      toggle,
      isMobile,
      onResize,
      // OnClick,
    }
  },
})
