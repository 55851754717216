














import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import FaqSection from '@/components/faq/Section.vue'

export default defineComponent({
  name: 'Faq',
  components: {
    FaqSection,
  },
  props: {},

  setup() {
    return {
      ...useGetters(['content']),
    }
  },
})
